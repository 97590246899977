<template>
    <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
    >
        <b-row
            v-for="(investment_document, index) in this.documents"
            :key="investment_document.id">
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Descripció" label-for="description">
                    <b-form-input v-model="investment_document.description" name="description"
                                  placeholder="Descripció" autocomplete="off"/>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Document" label-for="date-document">
                    <b-form-file
                        v-model="investment_document.document_file"
                        placeholder="Tria un document o deixa'l anar aquí..."
                        drop-placeholder="Tria un document o deixa'l anar aquí..."
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="investment_document.document" cols="1">
                <a :href="baseUrl+investment_document.document" target="_blank">
                    <b-button
                        variant="outline-success"
                        class="mt-0 mt-md-2"
                    >
                        <feather-icon
                            icon="EyeIcon"
                        />
                    </b-button>
                </a>
            </b-col>

            <b-col lg="2" md="12" sm="12">
                <b-form-group label="Data" label-for="birthday">
                    <flat-pickr
                        v-model="investment_document.date_update"
                        class="form-control"
                        placeholder="Data"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y'}"
                    />
                </b-form-group>
            </b-col>
            <b-col lg="1" md="12" sm="12" class="px-3 mt-2">
                <b-button
                    variant="outline-danger"
                    @click="removeItem(index, investment_document.id)"
                >
                    <feather-icon
                        icon="TrashIcon"
                    />
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="pb-2">
                <b-button
                    variant="primary"
                    @click="addNewRow"
                >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Afegir</span>
                </b-button>
            </b-col>
        </b-row>
        <b-row v-if="investment.documents">
            <b-col cols="12" class="d-flex justify-content-end">
                <b-button variant="primary" class="mr-1" @click="saveData">
                    Guardar canvis
                </b-button>
            </b-col>
        </b-row>
    </b-overlay>

</template>

<script>
import {BFormGroup, BFormFile, BFormInput, BRow, BCol, BOverlay, BFormRadio, BButton} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {mapState} from "vuex"
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import {baseUrl} from "@/constants/app"
import flatPickr from "vue-flatpickr-component"
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import Swal from "sweetalert2"
import helpers from "@/resources/helpers/helpers"


const {mapFields} = createHelpers({
    getterType: 'investments/getField',
    mutationType: 'investments/updateField',
})

export default {
    name: "Documents",
    components: {
        BFormGroup,
        BOverlay,
        BRow,
        BCol,
        BFormInput,
        BFormRadio,
        BButton,
        BFormFile,

        vSelect,
        flatPickr,

    },
    data() {
        return {
            baseUrl,
            showLoading: false,
            CatalanLocale
        }
    },
    computed: {
        ...mapState('investments', ['validation_investment']),
        ...mapFields({
            investment: 'investment',
            documents: 'investment.documents',
        }),
    },
    methods: {
        addNewRow() {
            this.investment.documents.push({
                description: null,
                document_file: null,
                document: null,
                date_update: null,
                investment_id: this.investment.id
            })
        },
        removeItem(index, documentId) {

            if (documentId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('investments/deleteInvestmentDocument', documentId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Registre eliminat correctament', 'CheckIcon', 'success')
                            this.investment.documents.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar registre', 'CheckIcon', 'Alert')
                        })
                    }
                })
            } else {
                this.investment.documents.splice(index, 1)
            }
        },
        saveData() {
            this.$store.dispatch('investments/updateInvestment', this.formatData())
            .then(response => {
                this.showToast('Documentació editada correctament!', 'CheckIcon', 'success')

                this.getInvestment()
            })
            .catch(error => {
                this.showToast('Error en editar la documentació!', 'AlertOctagonIcon', 'danger')
            })

        },
        formatData() {
            var formData = new FormData()

            this.appendFormData(formData, this.investment, 'investment')
            this.appendFormData(formData, this.investment.documents, 'documents')

            return formData
        },
        appendFormData(formData, data, rootName) {
            let root = rootName || ''
            if (data instanceof File) {
                formData.append(root, data)
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    this.appendFormData(formData, data[i], root + '[' + i + ']')
                }
            } else if (typeof data === 'object' && data) {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            this.appendFormData(formData, data[key], key)
                        } else {
                            this.appendFormData(formData, data[key], `${root}[${key}]`)
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data)
                } else {
                    formData.append(root, '')
                }
            }
        },
        getInvestment() {
            this.$store.dispatch('investments/getInvestment', {
                relations: ['client', 'documents'],
                id: this.$route.params.id ?? this.investment.id
            })
        },
        showToast(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: title,
                    icon: icon,
                    variant: variant,
                },
            })
        },
    }
}
</script>