<template>
    <div>
        <b-row>
            <b-col sm="7">
                <b-form-group label="Client" label-for="value">
                    <v-select
                        v-model="investment.client_id"
                        :options="clients"
                        label="name"
                        :reduce="(option) => option.id"
                        :disabled="disabled"
                    >
                        <template #no-options="{ }">
                            No s'han pogut carregar els clients
                        </template>
                        <template slot="option" slot-scope="option">
                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                        </template>
                    </v-select>
                    <small v-if="validation_investment.client_id" class="text-danger">
                        {{ validation_investment.client_id }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="5" md="12" sm="12">
                <b-form-group label="Tipus" label-for="value">
                    <v-select
                        v-model="investment.type"
                        :options="investmentTypes"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_investment.type" class="text-danger">
                        {{ validation_investment.type }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group label="Entitat" label-for="account-username">
                    <v-select
                        v-model="investment.financing_entity"
                        :options="investmentsFinancialEntities"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_investment.financing_entity" class="text-danger">
                        {{ validation_investment.financing_entity }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="4" md="12" sm="12">
                <b-form-group label="Tipus client" label-for="value">
                    <v-select
                        v-model="investment.investment_client_type"
                        :options="financingTypeClient"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_investment.investment_client_type" class="text-danger">
                        {{ validation_investment.investment_client_type }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="Data d'entrada" label-for="account-surnames">
                    <flat-pickr
                        v-model="investment.entry_date"
                        class="form-control"
                        :class="validation_investment.entry_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data d'entrada"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_investment.entry_date" class="text-danger">
                        {{ validation_investment.entry_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="Data de venciment" label-for="account-surnames">
                    <flat-pickr
                        v-model="investment.date_expiration"
                        class="form-control"
                        :class="validation_investment.date_expiration ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data de venciment"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_investment.date_expiration" class="text-danger">
                        {{ validation_investment.date_expiration }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="3">
                <b-form-group label="Tipus inversió" label-for="type-investment">
                    <b-form-input v-model="investment.investment_type" placeholder="Tipus inversió"
                                  :class="validation_investment.investment_type ? 'is-invalid': ''"/>
                    <small v-if="validation_investment.investment_type" class="text-danger">
                        {{ validation_investment.investment_type }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="Import" label-for="account-surnames">
                    <money class="form-control" v-model="investment.amount" v-bind="money" :class="validation_investment.amount ? 'is-invalid': ''"/>
                    <small v-if="validation_investment.amount" class="text-danger">
                        {{ validation_investment.amount }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group label="Segon titular" label-for="account-username">
                    <b-form-input v-model="investment.second_holder_name" placeholder="Segon titular"
                                  :class="validation_investment.second_holder_name ? 'is-invalid': ''"/>
                    <small v-if="validation_investment.second_holder_name" class="text-danger">
                        {{ validation_investment.second_holder_name }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="DNI segon titular" label-for="account-username">
                    <b-form-input v-model="investment.second_holder_dni" placeholder="DNI segon titular"
                                  :class="validation_investment.second_holder_dni ? 'is-invalid': ''"/>
                    <small v-if="validation_investment.second_holder_dni" class="text-danger">
                        {{ validation_investment.second_holder_dni }}
                    </small>
                </b-form-group>
            </b-col>

            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Estat" label-for="value">
                    <select v-model="investment.status" class="custom-select"
                            :class="'background-' + investment.status">
                        <option v-for="status in investmentStatus"
                                :key="status.value"
                                :selected="status.value === investment.status"
                                :value="status.value">
                            {{ status.name }}
                        </option>
                    </select>
                    <small v-if="validation_investment.status" class="text-danger">
                        {{ validation_investment.status }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BCol, BFormGroup, BFormInput, BRow, BFormFile } from 'bootstrap-vue'
import { Spanish as SpanishLocale } from 'flatpickr/dist/l10n/es'
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import {
    investmentsFinancialEntities,
    investmentTypes,
    investmentStatus,
    financingTypeClient,
} from "@/constants/finance"
import {baseUrl} from "@/constants/app"

const {mapFields} = createHelpers({
    getterType: 'investments/getField',
    mutationType: 'investments/updateField',
})

export default {
    name: "FormInvestmentView",
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BFormFile,
        vSelect,
        flatPickr,
    },
    props: ['disabled'],
    data() {
        return {
            SpanishLocale,
            clients: [],
            investmentsFinancialEntities,

            investmentTypes,
            investmentStatus,
            financingTypeClient,

            baseUrl
        }
    },
    computed: {
        ...mapState('investments', ['validation_investment']),
        ...mapFields({
            investment: 'investment',
        }),
    },
    created() {
        this.getClients()
    },
    methods: {
        getClients() {
            this.showLoading = true

            this.$store.dispatch('client/getAllClients', {
                id: this.$route.params.id,
                relations: ['responsible'],
            })
            .then(response => {
                this.clients = response
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.background-in_progress {
    color: #ffc107;
    border: 1px solid #ffc107;
}

.background-accepted {
    color: #28c76f;
    border: 1px solid #28c76f;
}

.background-cancelled {
    color: #82868b;
    border: 1px solid #82868b;
}

.background-denied {
    color: #ea5455;
    border: 1px solid #ea5455;
}

select option[value="in_progress"] {
    color: #ffc107 !important;
    background: rgba(255, 159, 67, 0.12);
}

select option[value="accepted"] {
    color: #28c76f !important;
    background: rgba(40, 199, 111, 0.12);
}

select option[value="cancelled"] {
    color: #82868b !important;
    background: rgba(130, 134, 139, 0.12);
}

select option[value="denied"] {
    color: #ea5455 !important;
    background: rgba(234, 84, 85, 0.12);
}
</style>