formDocumentsView.vue<template>
  <div>
    <b-card class="p-1 p-lg-0">
      <b-tabs align="left">
        <b-tab active title="Informació general" lazy>
          <b-overlay
              variant="white"
              :show="showLoading"
              spinner-variant="primary"
              blur="0"
              opacity=".75"
              rounded="sm"
          >
            <form-investment :disabled="edit"/>
          </b-overlay>
            <b-row align-h="start">
                <b-col cols="5">
                    <b-button v-if="!edit" variant="primary" class="mt-2 mr-1" @click="saveData">
                        Desar canvis
                    </b-button>
                    <b-button v-else variant="primary" class="mt-2 mr-1" @click="saveData">
                        Actualitzar canvis
                    </b-button>
                    <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
                        Cancel·lar
                    </b-button>
                </b-col>
            </b-row>
        </b-tab>
        <b-tab v-if="investment.id" title="Dades personals" lazy>
          <form-data-address-client :disabled="true"/>
        </b-tab>
          <b-tab v-if="investment.id" title="Documents" lazy>
          <form-documents :disabled="true"/>
        </b-tab>
      </b-tabs>

    </b-card>

  </div>
</template>

<script>
import {BButton, BCard, BCol, BTab, BTabs, BOverlay, BRow} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import {INVESTMENTS_LIST} from "@/router/web/constants"
import ToastificationContent from "@core/components/toastification/ToastificationContent"

import FormDataAddressClient from "@/views/clients/form/formClientPersonalDataView"
import FormInvestment from "@/views/finance/investments/form/formInvestmentView"
import FormDocuments from "@/views/finance/investments/form/components/formDocumentsView"

import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
  getterType: 'investments/getField',
  mutationType: 'investments/updateField',
})

export default {
  name: "InvestmentFormView",
  components: {
    BCol,
    BRow,
    BButton,
    BCard,
    BOverlay,
    BTab,
    BTabs,

    FormInvestment,
    FormDocuments,
    FormDataAddressClient
  },
  data() {
    return {
      users: [],

      client: null,
      edit: false,
      showLoading: false,
      SpanishLocale,

    }
  },
  computed: {
    ...mapState('investments', ['validation_investment']),
    ...mapFields({
      investment: 'investment',
    }),
  },
  created() {
    this.$store.commit('investments/RESET_STATE')

    if (this.$route.params.id) {
      this.edit = true
      this.getInvestment()
    } else {
      this.edit = false
    }
  },
  methods: {
    getInvestment() {
      this.showLoading = true

      this.$store.dispatch('investments/getInvestment', {
          id: this.$route.params.id ?? this.investment.id,
        relations: ['client','client.clientPersonalData', 'documents'],
      })
      .then(response => {
        this.showLoading = false
      })
      .catch(error => {
        this.showLoading = false
      })
    },
    cancel() {
      this.$router.push(INVESTMENTS_LIST)
    },
    formatData() {
      var formData = new FormData()
      this.appendFormData(formData, this.investment, 'investment')
      return formData
    },
    appendFormData(formData, data, rootName) {
      let root = rootName || ''
      if (data instanceof File) {
        formData.append(root, data)
      } else if (Array.isArray(data)) {
        for (var i = 0; i < data.length; i++) {
          this.appendFormData(formData, data[i], root + '[' + i + ']')
        }
      } else if (typeof data === 'object' && data) {
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            if (root === '') {
              this.appendFormData(formData, data[key], key)
            } else {
              this.appendFormData(formData, data[key], `${root}[${key}]`)
            }
          }
        }
      } else {
        if (data !== null && typeof data !== 'undefined') {
          formData.append(root, data)
        } else {
          formData.append(root, '')
        }
      }
    },
    saveData() {
      if (!this.investment.id) {
        this.$store.dispatch('investments/createInvestment', this.formatData())
        .then(response => {

          this.showToast('Inversió creat correctament!', 'CheckIcon', 'success')
          this.investment.id = response.id
          this.investment.document = response.document
        })
        .catch(error => {
          this.showToast('Error en crear la inversió!', 'AlertOctagonIcon', 'danger')
        })
      } else {
        this.$store.dispatch('investments/updateInvestment', this.formatData())
        .then(response => {
          this.showToast('Dades actualitzades correctament!', 'CheckIcon', 'success')
          this.investment.document = response.document
          this.investment.document_chat = response.document_chat
        })
        .catch(error => {
          this.showToast('Error en editar la inversió!', 'AlertOctagonIcon', 'danger')
        })
      }
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>